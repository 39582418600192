import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'
import { default as Header } from '../common/header'
import { default as Footer } from '../common/footer'
import { default as Heroshot } from '../common/heroshot'
import { default as Contact } from '../homepage/Contact'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import DigitalOcean from '../../assets/vectors/PartnerBadges_blue.svg'
import Conekta from '../../assets/vectors/conekta.svg'
import Mailchimp from '../../assets/vectors/mailchimp.svg'
import Stripe from '../../assets/vectors/stripe.svg'
import Fedex from '../../assets/vectors/fedex.svg'

export default class Page extends React.Component<{}, {}> {
    public render() {
        return (
            <section className={styles.Container}>
                <Header 
                    title="Especialistas en eCommerce | Juvasoft"
                    description={`Juvasoft trabajará contigo en una estrategia
                    integral para ayudarte a que realmente vendas
                    en línea.`}
                    link="https://juvasoft.com/es/ecommerce"
                    landing={true}
                 />
                <Heroshot title={`Y tú, ¿Ya vendes en línea?`}
                subtitle={`
                    Juvasoft trabajará contigo en una estrategia
                    integral para ayudarte a que realmente vendas
                    en línea. Nosotros te ayudaremos en todo el proceso – desde montar tu
                    eCommerce, hasta generar una estrategia de tráfico y
                    optimización de conversiones.`} />

                <div className={styles.Ecommerce}>
                    <div className={styles.Services}>
                        <header>
                            <h1>Conoce nuestros servicios</h1>

                            <p className={styles.Subtitle}>
                                Todos leemos lo fácil y rápido que es tener una tienda el linea;
                                sin embargo, en la mayoría de los casos, publicamos la tienda y esperamos, sin mucha suerte, 
                                a que caigan nuestras primeras ventas. <strong>En Juvasoft, te guiaremos en el camino
                                para que puedas vender en línea con el éxito que te merceres.</strong>
                            </p>
                        </header>

                        <div className={styles.Service}>
                            <h2>Consultoría y Planeación</h2>

                            <p>Estudiamos tu negocio y con tu ayuda, diseñamos el proyecto.</p>

                            <ul>
                                <li>Consultoría en eCommerce</li>
                                <li>Análisis y planeación</li>
                                <li>Configuración de dominio</li>
                                <li>Correos <em>G Stuite</em></li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Diseño y UX</h2>

                            <p>Creamos un diseño uniforme con tu identidad corporativa, único para ti.</p>

                            <ul>
                                <li>Diseño a medida</li>
                                <li>Diseño responsivo (smartphone, tablet, PC)</li>
                                <li>Experiencia de usuario</li>
                                <li>Identidad corporativa</li>
                                <li>Fotografía de producto</li>
                                <li>Video corporativo</li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Desarrollo y Configuración</h2>

                            <p>Implementamos tu tienda en línea con las mejores prácticas del eCommerce.</p>

                            <ul>
                                <li>Desarrollo Front-End</li>
                                <li>Optimización de Performance</li>
                                <li>Optimización de Conversiones</li>
                                <li>Estrategia de SEO</li>
                                <li>Carga de productos</li>
                                <li>Integración con métodos de pago: Conekta, Stripe, Paypal, OpenPay, PayU, PagoFacil, Mercado Pago</li>
                                <li>Configuración de envíos</li>
                            </ul>
                        </div>

                        <div className={styles.Service}>
                            <h2>Soporte y Capacitación</h2>

                            <p>Contarás con el respaldo de nuestro equipo los 365 días del año.</p>

                            <ul>
                                <li>Capacitación y manuales</li>
                                <li>Reportes Google Analytics</li>
                                <li>Consultoría de Marketing Digital</li>
                                <li>Soporte técnico</li>
                            </ul>
                        </div>

                        <div className={styles.CallToAction}>
                            <AnchorLink offset="0" href="#Contact">
                                Contacta un asesor
                            </AnchorLink>
                        </div>
                    </div>

                    <div className={styles.Brands}>
                        <header>
                            <h1>Tecnología y Servicios de Calidad</h1>

                            <p className={styles.Subtitle}>
                                Integramos las mejores tecnologías a tu tienda en línea.
                                Con nuestros socios comerciales, aseguramos brindarte el
                                respaldo tecnológico y de negocios que tu empresa necesita.
                            </p>
                        </header>

                        <div className={styles.Grid}>
                            <DigitalOcean />
                            <Conekta />
                            <Fedex />
                            <Mailchimp />
                            <Stripe />
                        </div>
                    </div>
                </div>
                <Contact 
                    title={`¿Ya estás vendiendo en internet?`} 
                    subtitle={`Ponte en contacto con nosotros y un asesor encontrará un plan a la medida de tu empresa.`}
                />
                <Footer />
            </section>
        )
    }
}